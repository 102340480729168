/* Navigation.css */

/* Reset the list style and set up flexbox for the navigation */
.navul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between; /* This will space out the list items */
  align-items: center;
  background: #fff; /* Set a background color if needed */
  height: 60px; /* Adjust to your navbar's desired height */
  list-style-type: none;
}

ul li {
  list-style-type: none;
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
}

a :hover {
  text-decoration: none;
  color: #4591c3;
  cursor: pointer;
}




/* Styling for the navigation links */
.navli {
  padding: 10px;
  list-style: none;
  list-style-type: none;
}

.homeImage img {
  width: 30px; /* Adjust to your preference */
  height: auto;
}



/* Center the brand text */
.brand-text {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  letter-spacing: 2px;
  pointer-events: none; /* This allows clicks to pass through */
  top: 0; /* Position at the top */
  left: 0;
  z-index: 1; /* Lower z-index than clickable elements */
}

/* Ensure clickable elements can be interacted with */
.homeImage, .profile-button {
  z-index: 2; /* Higher z-index for clickable elements */
  
}

/* Additional styling for buttons and links within the dropdown */
.profile-dropdown {
  position: absolute;
  top: 100%; /* Position below the profile button */
  right: 0; /* Align the dropdown to the right edge of the profile button */
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1000; /* High z-index to ensure it's on top */
  width: auto; /* Adjust width as needed */
  min-width: 160px; /* Minimum width */
}

/* Profile button that triggers dropdown */
.profile-button {
  position: relative; /* Establish a positioning context for dropdown */
}

.profile-dropdown,
.profile-dropdown button {
  padding: 10px;
  text-align: left;
}

/* Hover effect for dropdown items */
.profile-dropdown:hover,
.profile-dropdown button:hover {
  background-color: #f2f2f2;
  color:#4591c3;
}

.wors {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-family: 'Courier New', Courier, monospace;

}

.loginWors:hover {
  background-color: #2aa58c; /* Lighter red on hover */
  transform: translateY(-3px); /* Slight move up on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Increased shadow on hover */
  cursor: pointer;
  color: white;
  padding: 5px;

}

.signupWors:hover {
  background-color: #2aa58c; /* Lighter red on hover */
  transform: translateY(-3px); /* Slight move up on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Increased shadow on hover */
  cursor: pointer;
  color: white;
  padding: 5px;
  
  }

  .wors1 {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 16px;
    font-family: 'Courier New', Courier, monospace;
  
  } 

.logoutButt {
    background-color: #edededa8;
    color: rgb(3, 99, 82);
    font-size: 16px;
    border: 1px solid transparent; /* Ensure there is a border definition if needed */

}




  button.logoutButt {
    background-color: #edededa8;
    color: rgb(3, 99, 82);
    font-size: 16px;
    border: 1px;
    cursor: pointer;
    padding-left: 50px;
    padding-right: 50px;
  }


  .logoutButt:hover {
    background-color: #2c8775; /* Lighter red on hover */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Increased shadow on hover */
    cursor: pointer;
    color: rgb(225, 166, 241);
  }

.profile-dropdown button {
  background: none;
  border: none;
  width: 100%;
  text-align: left;
}


.hidden {
  display: none;
}

.close-modal-button {
  display: inline-block;
  justify-content: center;
  align-items: center;
  width: 60%;
  padding: 10px 20px;
  gap: 50px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 14px;
  font-size: 20px;
  color: #ffffff;
  text-decoration: none;
  opacity: 0.8;
  background-color: rgb(39, 95, 56);
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s;
  
  
} 

.close-modal-button:hover {
  background-color: #2aa58c; /* Lighter red on hover */
  transform: translateY(-3px); /* Slight move up on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Increased shadow on hover */
  cursor: pointer;
}


.ddbutton {
  background-color: #edededa8;
  color: rgb(3, 99, 82);
  padding: 16px;
  font-size: 16px;
  border: 1px;
  cursor: pointer;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s;
}

.ddbutton:hover {
  background-color: #2aa58c; /* Lighter red on hover */
  transform: translateY(-3px); /* Slight move up on hover */
  color: white;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Increased shadow on hover */
  cursor: pointer;
}

a:hover {
  color: #ffffff;
}


.subhead-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 55px;
  margin-bottom: 20px;
  font-family: 'Courier New', Courier, monospace;
} 

.subhead-nav a {
  color: #000000;
  text-decoration: none;
  padding: 10px;
  border-radius: 25px;
  box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.8s, transform 0.3s;
} 

.subhead-nav a:hover {
  background-color: #2aa58c; /* Lighter red on hover */
  transform: translateY(3px); /* Slight move up on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Increased shadow on hover */
  cursor: pointer;
  color: white;
} 