.landing-page-container {
    display: flex;
    justify-content: space-between; /* Space between the main elements */
    align-items: flex-start; /* Align items to the top */
    height: calc(100vh - 60px); /* Adjusted for a navbar height */
    margin-top: 60px; /* Space for the navbar */
    position: relative; /* Needed for absolute positioning of pillars */
    
  }

  .landing-topic, .landing-describe {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    color: #333; /* Dark text for contrast */
    padding: 10px; /* Padding around text */
    border-radius: 8px; /* Optional: rounded corners for the background */
    display: inline-block;
    margin: 0 auto; /* Centering the block elements */
    width: fit-content; /* Adjust width to fit the content */
  }

  .landing-describe {
    background-color: rgba(255, 255, 255, 0.8); /* Adjust opacity */}
  
  .comment {
    background-color: rgba(255, 255, 255, 0.8); /* Adjust opacity as needed */
    color: #333; /* Assuming you want dark text here as well */
    /* Rest of your styles */
  }


  @keyframes typing {
    from { width: 0; white-space: nowrap;}
    to { width: 100%; border-right-color: transparent; }
  }
  
  @keyframes blinkCursor {
    50% { border-right-color: transparent; }
  }
  
  .landing-topic {
    display: inline-block;
    font-size: 50px;
    font-family: 'Times New Roman', Times, serif;
    overflow: hidden; /* You might want to remove or adjust this if you want to see the wrapping */
    border-right: 3px solid rgba(0, 0, 0, 0.75); /* Cursor style */
    margin-bottom: 0.5rem;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    animation: typing 1.5s steps(30, end) forwards, blinkCursor 0.96s step-end infinite;
  }

  @media (max-width: 768px) {
  .landing-topic {
  font-size: 30px;
  animation: typing 2s steps(20, end) forwards, blinkCursor 0.2s step-end infinite;
  }}



  .landing-describe{
  display: inline-block;
    font-size: 20px;
    font-family: 'Times New Roman', Times, serif;
  
    margin-bottom: 1.4rem;
    text-align: center;
  }
  
  .pillar {
    position: absolute; /* Absolute positioning */
    bottom: 0; 
    top: 60px; 
    z-index: -1;
    
  }
  
  .left-pillar {
    left: 0;
  }
  
  .right-pillar {
    right: 0;
  }
  
  .left-pillar img, .right-pillar img {
    height: 100%;
    object-fit: cover; 
  }
  
  .topic-of-the-day-container {
    width: 60%; 
    text-align: center;
    margin: 0 auto;
  }
  
  .comments-preview {
    display: flex;
    flex-direction:column;
    flex-wrap: wrap; 
    justify-content: center; 
    align-items: center;
    gap: 15px; 
  }
  
  @keyframes gradientBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.comment {
  /* Define a linear gradient background with your choice of colors */
  margin-top: 10px;
  background: linear-gradient(-45deg, 
  rgba(61, 100, 56, 0.7), 
  rgba(29, 175, 134, 0.7), 
  rgba(0, 151, 185, 0.7), 
  rgba(189, 0, 123, 0.7), 
  rgba(61, 100, 56, 0.7));
  background-size: 400% 400%;
  color: white;
  border-radius: 15px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  /* Animation */
  animation: gradientBackground 15s ease infinite;
}

  .comment {
    padding: 30px;
    border-radius: 5px;
    max-width: calc(49.333% - 20px); 
    box-shadow: 0 2px 4px rgba(0,0,0,0.7); 
  }
  
  .comment-username {
    font-weight: bold;
  }
  

  
  
  @media (max-width: 768px) {
    .pillar {
      display: none; 
    }
  
    .topic-of-the-day-container {
      width: 100%;
    }
  
    .comment {
      max-width: 100%; 
    }
  }
  

  .comment-content-landing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 14px;
  }

  .said-landing{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 5px;
    opacity: 0.3;
  }
  

.see-all-comments, .topcs { 
  display: inline-block;
  justify-content: center;
  font-family: 'Courier New', Courier, monospace;
  align-items: center;
  padding: 10px 20px;
  gap: 50px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 14px;
  font-size: 20px;
  color: #ffffff;
  text-decoration: none;
  opacity: 0.8;
  background-color: rgb(174, 53, 108);
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s;
}

.see-all-comments:hover, .topcs:hover {
  background-color: #2aa58c; /* Lighter red on hover */
  transform: translateY(-3px); /* Slight move up on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Increased shadow on hover */
}

.see-all-comments::before, .topcs::before {
  display: inline-block;
  content: ''; /* Use content property to insert the icon */
  /* Insert background image for icon using background property */
  /* Example: */
  /* background: url('path-to-your-icon.png') no-repeat center center; */
  /* Adjust size as necessary */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.see-all-comments:hover, .topcs:hover {
  animation: pulse 1s infinite;
}

.about-button {
  display: inline-block;
  justify-content: center;
  font-family: 'Courier New', Courier, monospace;
  align-items: center;
  padding: 10px 20px;
  gap: 50px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 14px;
  font-size: 20px;
  color: #ffffff;
  text-decoration: none;
  opacity: 0.8;
  background-color: rgb(174, 53, 108);
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s;
}

.about-button:hover {
  background-color: #2aa58c; /* Lighter red on hover */
  transform: translateY(-3px); /* Slight move up on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Increased shadow on hover */
}