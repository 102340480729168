.comments-container {
    max-width: 1200px; /* Set a max-width for the container */
    margin: 0 auto; /* Center the container */
    padding: 2rem;
    font-family: 'Arial', sans-serif; /* Use a modern font-family */
  }
  
  .comment-section {
    background: #fc6796; /* Light pink background for the comment bubble */
    background: linear-gradient(55deg, #fc6796, #266b8d, #362bae, #1a804d);
    /* background: linear-gradient(45deg, #fc6796, #266b8d); Gradient background  */
    border-radius: 25px; /* Rounded corners for the bubble */
    padding: 2rem;
    margin-top: 2rem; /* Add space above the comment bubble */
    position: relative; /* Position relative for the image to overlap */
    z-index: 1; /* Ensure the z-index is higher than the image */
  }
  
  .comment-image {
    position: absolute;

    top: 100px; /* Adjust for the image to overlap */
    right: -180px; /* Position it towards the right */
    width: 800px; /* A larger width for the image */
    z-index: 0; /* Place it behind the comment bubble */
  }
  
  .comment {
    margin-bottom: 1rem;
    border-radius: 15px; /* Slightly rounded corners for the comments */
    border-width: 500px;
    padding: 1rem;
    box-shadow: 0 4px 6px rgba(0,0,0,0.7); /* Soft shadow for depth */
  }
  
  .comment-topic {
    font-size: 50px;
    font-family: 'Times New Roman', Times, serif;
    margin-bottom: 0.5rem;
    text-align: center;
  }
  
  .comment-actions {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    gap: 0.5rem; /* Add space between buttons */
    margin-top: 1rem; /* Add space above the buttons */
  }
  
  .comment-actions button {
    background: #8d8ab1; /* Bootstrap primary color for buttons */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .comment-actions button:hover {
    background: #0056b3; /* A darker shade for hover state */
  }
  
  .comment-actions button:disabled {
    background: #ccc;;
    cursor: not-allowed;  
  }
  
  .new-comment-form {
  background: rgb(104, 220, 255, 0.4); 
  border-radius: 25px; 
  padding: 1rem;
  margin-top: 2rem; 
  width: 90%;
  margin: 0 auto;
  margin-top: 10px;
  z-index: 3;
  }

  
  
  .new-comment-form label {
  display: block;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: rgb(154, 79, 203);
  text-align: center;
  box-shadow: #0056b3;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  text-shadow:
    -0.7px -0.7px 0 #e0cfcf,
    0.7px -0.7px 0 #ffffff,
    -0.7px 0.7px 0 #ffffff,
    0.7px 0.7px 0 #ffffff;
  }
  
  .new-comment-form textarea {
  width: 100%; 
  border-radius: 5px; 
  border: 1px solid #ccc; 

  height: 100px;
  resize: none; 
  }
  
  .new-comment-form .error {
  color: #d32f2f; 
  margin-top: 0.5rem; 
  }
  
  .new-comment-form button {
  display: block; 
  width: 100%;
  background: #8d8ab1; 
  color: white;
  border: none;
  border-radius: 5px;
  padding: 1rem; 
  margin-top: 1rem; 
  cursor: pointer;
  transition: background-color 0.3s ease; 
  font-size: 1.5rem;
  }
  
  .new-comment-form button:hover {
  background: #0056b3; 
  }
  
 
  .login-to-comment {
  background: #f5dfe6; 
  font-weight: bold;
  color: #362bae;
  text-align: center;
  padding: 1rem;
  border-radius: 25px;
  display: block; 
  text-decoration: none;
  margin-top: 2rem; 
  transition: background-color 0.3s, color 0.3s; 
  }
  
  .login-to-comment:hover {
  background: #ffffff; 
  color: #0056b3; 
  cursor: pointer;
  }
  

  .comment-username {
    font-weight:bold; /* Make username bold */
    font-size: 1.5rem;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: rgb(255, 255, 255);
    padding: 0.5rem 0.8rem; /* Padding around the text */
    border-radius: 10px; /* Rounded corners for highlight */
    margin-right: 0.5rem; /* Space between username and comment */
    text-shadow:
    -0.7px -0.7px 0 #000,
    0.7px -0.7px 0 #000,
    -0.7px 0.7px 0 #000,
    0.7px 0.7px 0 #000;
  }
  
  .comment-content {
    margin-top: 13px;
    text-indent: 1rem; /* Indent for the first line of the comment */
    display: block; /* Make content a block to apply text-indent */
  }


  .comment input[type="text"] {
    height: 10rem; /* Set the height of the input */
    width: 100%; /* Make the input take full width of its parent */
    margin-top: 0.5rem; /* Add a bit of margin on top for spacing */
    border: 1px solid #ccc; /* Add a light border to the input */
    border-radius: 4px; 
  }
  
  .comment-edit-textarea {
    width: 100%; /* Make textarea take full width */
    height: auto; /* Adjust height automatically */
    min-height: 50px; /* Minimum height */
    border: 1px solid #ccc; /* Same border as other inputs */
    border-radius: 4px; /* Rounded corners */
    margin-top: 10px;
    font-family: inherit; /* To match the font of other elements */
    resize: vertical; /* Allow vertical resizing, none for no resizing */
  }
  

  @media (max-width: 768px) {
    .comment-image {
      display: none; /* Hide the image on smaller screens */
    }
  }

  .comment-section {
    /* Existing styles */
    position: relative; /* Ensure this is set to relative */
}

.comment-image {
    /* Existing styles */

    z-index: -1; /* Set image behind the input fields */
}

/* Ensure the z-index for inputs and textarea is higher than the image */
.comment input[type="text"],
.comment-edit-textarea,
.new-comment-form textarea {
    z-index: 10; /* Higher z-index to ensure it's above the image */
    /* Other styles */

}

/* You can also add a background to the text areas to improve visibility */
.comment-edit-textarea,
.new-comment-form textarea {
    background: rgba(255, 255, 255, 0.6); /* Semi-transparent white background */
}

/* Adjust the z-index for the entire container of the comment including actions */
.comment {
    z-index: 2; /* Above the thinker image */
    position: relative; /* Needed because of changing z-index */
}


.comment {
  /* Existing styles */
  word-wrap: break-word; /* This will ensure text wraps within the bubble */
  overflow-wrap: break-word; /* Use this for better support in some browsers */
  max-width: 100%; /* Ensure the comment doesn't exceed the width of its container */
}

.comment-content {
  /* Existing styles */
  word-wrap: break-word; /* This will ensure text wraps within the bubble */
  overflow-wrap: break-word; /* Use this for better support in some browsers */
}

/* Ensure that the new comment form has a higher z-index and is positioned relatively */
.new-comment-form {
  /* Existing styles */
  z-index: 10; /* Higher z-index to ensure it's above the image */
  position: relative; /* Add relative positioning */
}